import axios from 'axios';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import ValidationResult from './ValidationResult';
import PaymentPopup from './PaymentPopup';
import RemainingCodesPopup from './RemainingCodesPopup';
import InfoPopup from './InfoPopup';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ValidationPage = () => {
  const [mcNumber, setMcNumber] = useState('');
  const [purchaseCode, setPurchaseCode] = useState('');
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [isValidationPopupOpen, setIsValidationPopupOpen] = useState(false);
  const [loading, setLoading] = useState({ oneLookup: false, tenLookups: false, validation: false });
  const [validationResult, setValidationResult] = useState(null);
  const [whyNull, setWhyNull] = useState('');
  const [docType, setDocType] = useState('MC');
  const [isRemainingCodesPopupOpen, setIsRemainingCodesPopupOpen] = useState(false);
  const [isPaymentOptionshow, setIsPaymentOptionshow] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };


  useEffect(() => {
    const { code } = queryString.parse(window.location.search);
    if (code) {
      setPurchaseCode(code);
    }
  }, []);

  useEffect(() => {
    const { device } = queryString.parse(window.location.search);
    if (device === 'iphone') {
      setIsPaymentOptionshow(false);
    }
  }, []);

  const handleValidate = (e) => {
    e.preventDefault();
    let formattedMcNumber = mcNumber;
    if (docType === 'MC' && !mcNumber.startsWith('MC')) {
      formattedMcNumber = `MC${mcNumber}`;
    }
    setLoading((prev) => ({ ...prev, validation: true }));
    axios.post(`${BASE_URL}/api/lookup/get-company`, { "mc_number": formattedMcNumber, "code": purchaseCode, "type": docType === 'MC' ? 'docketNumber' : 'dotNumber' })
      .then((response) => {
        setLoading((prev) => ({ ...prev, validation: false }));
        setValidationResult(response.data);
        setIsValidationPopupOpen(true);
      })
      .catch((error) => {
        setLoading((prev) => ({ ...prev, validation: false }));
        console.error('Validation error:', error);
        setValidationResult(null);
        setIsValidationPopupOpen(true);
        setWhyNull(error.response.data.message);
      });
  };

  const openRemainingCodesPopup = () => {
    setIsRemainingCodesPopupOpen(true);
  };

  const closeRemainingCodesPopup = () => {
    setIsRemainingCodesPopupOpen(false);
  };


  const openPaymentPopup = () => {
    if (isPaymentOptionshow) {
      setIsPaymentPopupOpen(true);
    }
    else {
      handleOpenPopup();
    }
  };

  const closePaymentPopup = () => {
    setIsPaymentPopupOpen(false);
  };

  const closeValidationPopup = () => {
    setIsValidationPopupOpen(false);
  };

  const purchaseLookups = (endpoint, plan) => {
    setLoading((prev) => ({ ...prev, [plan]: true }));
    axios.get(`${BASE_URL}/api/payment/${endpoint}`)
      .then((response) => {
        setLoading((prev) => ({ ...prev, [plan]: false }));
        if (response.data) {
          console.log('Payment success:', response.data);
          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
          }
        }
      })
      .catch((error) => {
        setLoading((prev) => ({ ...prev, [plan]: false }));
        console.error('Payment error:', error);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-white relative">
      <header className="w-full bg-[#4c84ff] text-white py-4">
        <nav className="container mx-auto flex justify-end items-center px-4 flex-wrap gap-4">
          <div className="flex space-x-4">
            <p onClick={openPaymentPopup} className="text-white cursor-pointer">Pricing</p>
            <a href="/contact" className="text-white">Contact Us</a>
            <a href="/about" className="text-white">About Us</a>
          </div>
          <button onClick={openRemainingCodesPopup} className="bg-white text-[#4c84ff] px-4 py-2 rounded-lg mt-2 sm:mt-0">
              Search for Remaining Codes
            </button>
          <button onClick={openPaymentPopup} className="bg-white text-[#4c84ff] px-4 py-2 rounded-lg mt-2 sm:mt-0">
            Buy Credits
          </button>
        </nav>
      </header>
      <InfoPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />

      {isRemainingCodesPopupOpen && (
        <RemainingCodesPopup onClose={closeRemainingCodesPopup} />
      )}


      <div className="w-full flex flex-col items-center mt-8">
        <img className="w-70 mb-4" src="/white-logo.png" alt="logo" />
        <h1 className="text-3xl font-bold text-[#4c84ff]">What's Your MC Number?</h1>
        <p className="mt-4 max-w-4xl mx-auto text-gray-700 text-center px-4">
          When handling a freight shipment, the first critical question often asked by brokers, shippers, or motor carriers is, "What's your MC Number?" This vital step ensures proper vetting and compliance.
          <br /><br />
          Our platform delivers fast, accurate assurance that brokers and motor carriers meet all state and federal registration and operating authority requirements. With our service, you can purchase code credits for quick, on-demand lookups or opt for detailed, unlimited searches at FreightValidate.com.
          <br /><br />
          Harness the power of the "Search by MC/DOT Number" feature, a robust tool designed to provide specific company information swiftly and accurately. This feature enhances validation processes, ensuring regulatory compliance and detecting potential fraudulent or double brokering threats.
          <br /><br />
          Choose WhatsYourMCNumber.com (powered by FreightValidate) for unparalleled insights into the reliability of carriers and brokers in the United States and Canada. Our platform merges cutting-edge technology with extensive industry expertise, a deep understanding of 49 CFR regulations, and Public Law 112-141 (MAP-21). With years of experience, we provide objective, impartial, and factual data in our Identity and Compliance Verification process—no predictive analytics, no guesswork, no hypotheticals. Just the facts.
          <br /><br />
          Empower your decisions with the confidence that comes from using the most trusted and accurate verification service available. Don't leave compliance and safety to chance—choose WhatsYourMCNumber.com today.
        </p>
      </div>

      <div className='flex flex-col items-center w-full p-5 mb-5 gap-5 mt-10'>
        {/* <button className="bg-[#4c84ff] text-white px-4 py-2 rounded-lg mb-5" onClick={openPaymentPopup}>
          Purchase credits now
        </button> */}
        <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg w-full max-w-md border border-[#4c84ff] md:w-full w-[90%]">
          <form onSubmit={handleValidate}>
            <div className="mb-4">
              <label htmlFor="docType" className="block text-sm font-bold text-black-700">
                Enter Docket or DOT Number
              </label>
              <div className="flex items-center mt-2">
                <select
                  id="docType"
                  value={docType}
                  onChange={(e) => setDocType(e.target.value)}
                  className="mr-2 p-2 border rounded shadow-sm focus:ring-[#4c84ff] focus:border-[#4c84ff]"
                >
                  <option value="MC">MC</option>
                  <option value="DOT">DOT</option>
                </select>
                <input
                  type="text"
                  id="mcNumber"
                  value={mcNumber}
                  onChange={(e) => setMcNumber(e.target.value)}
                  placeholder={`Enter ${docType} number`}
                  className="p-2 w-full border rounded shadow-sm focus:ring-[#4c84ff] focus:border-[#4c84ff]"
                  required
                />
              </div>
            </div>
            <div className="mb-6">
              <label htmlFor="purchaseCode" className="block text-sm font-bold text-black-700">
                Enter Code
              </label>
              <input
                type="text"
                id="purchaseCode"
                value={purchaseCode}
                onChange={(e) => setPurchaseCode(e.target.value)}
                className="mt-2 p-2 w-full border rounded shadow-sm focus:ring-[#4c84ff] focus:border-[#4c84ff]"
                required
              />
            </div>
            {!purchaseCode && (
              <div className="text-sm mb-6">
                Don't have a purchase code yet? <button type="button" className="text-[#4c84ff]" onClick={openPaymentPopup}>Purchase now</button>
              </div>
            )}
            <button
              type="submit"
              className="w-full bg-[#4c84ff] text-white py-2 rounded-lg transition duration-200"
              disabled={loading.validation}
            >
              {loading.validation ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Validate'
              )}
            </button>
          </form>
        </div>

        {/* show a image */}
        <img className="w-70 mt-8" src="/terms-of-use.png" alt="Terms of Use" />
        {isPaymentOptionshow && (
        <div id="pricing" className="mt-8 w-full max-w-md bg-gray-100 p-6 rounded-lg shadow-md text-center border border-gray-300">
          <h2 className="text-xl font-bold mb-4 text-[#4c84ff]">Pricing</h2>
          <ul className="text-gray-700 text-lg">
            <li className="mb-2">1 Lookup: <span className="font-semibold">$4.99</span></li>
            <li className="mb-2">10 Lookups: <span className="font-semibold">$15.99</span></li>
            <li className="mb-2">25 Lookups: <span className="font-semibold">$29.99</span></li>
            <li className="mb-2">50 Lookups: <span className="font-semibold">$59.99</span></li>
            <li className="mb-2">100 Lookups: <span className="font-semibold">$109.99</span></li>
            <li className="mb-2">1000 Lookups: <span className="font-semibold">$599.00</span></li>
          </ul>
          {/* Add buy credit button */}
          <button className="bg-[#4c84ff] text-white px-4 py-2 rounded-lg mt-4" onClick={openPaymentPopup}>
            Buy Credits
          </button>
        </div>
        )}
      </div>

      {isPaymentPopupOpen && (
        <PaymentPopup
          closePaymentPopup={closePaymentPopup}
          purchaseLookups={purchaseLookups}
          loading={loading}
        />
      )}

      {isValidationPopupOpen && (
        <ValidationResult
          validationResult={validationResult}
          closeValidationPopup={closeValidationPopup}
          whyNull={whyNull}
        />
      )}

      <div className="mt-auto py-5 w-full bg-gray-200 text-center">
        <a href="/PrivacyNotice.pdf" target="_blank" className="text-sm text-[#4c84ff] mx-2">Privacy Policy</a>
        <a href="/CollaboRateTermsofServiceUpdated8-24.pdf" target="_blank" className="text-sm text-[#4c84ff] mx-2">Terms and Conditions</a>
      </div>
    </div>
  );
};

export default ValidationPage;
