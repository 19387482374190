import React, { useState, useEffect, useRef } from 'react';

const ValidationResult = ({ validationResult, closeValidationPopup, whyNull }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const resultRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (resultRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = resultRef.current;
        setShowScrollButton(scrollHeight > clientHeight && scrollTop < scrollHeight - clientHeight - 20);
      }
    };

    if (resultRef.current) {
      resultRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (resultRef.current) {
        resultRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (resultRef.current) {
      resultRef.current.scrollTo({ top: resultRef.current.scrollHeight, behavior: 'smooth' });
    }
  };

  return (
    <div  ref={resultRef} className="fixed inset-0 flex items-start justify-center bg-black bg-opacity-75 z-50 animate-fadeIn overflow-y-auto">
      <div className={`p-8 rounded-lg w-full max-w-md relative border border-gray-300 ${validationResult ? 'bg-white text-black rounded' : 'bg-white'}`}>
        <button
          className="absolute top-4 right-4 text-black text-2xl"
          onClick={closeValidationPopup}
        >
          &times;
        </button>
        {validationResult ? (
          <div className="flex flex-col items-center">
            {validationResult.data.status === 'invalid' ? (
              <svg className="h-20 w-20 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : validationResult.data.status === 'warning' ? (
              <img src="/warn.png" alt="Warning" className="w-16 h-16 mb-4" />
            ) : (
              <img src="/check.png" alt="Validated" className="w-16 h-16 mb-4" />
            )}
            {validationResult.data.status === 'invalid' ? (
              <p className="text-[1.3rem] text-red-500 text-center mt-5 mb-5">Unable to Validate Additional Vetting Recommended</p>
            ) : validationResult.data.status === 'warning' ? (
              <p className="text-[1.3rem] text-yellow-500 text-center mt-5 mb-5">Unable to Completely Validate</p>
            ) : (
              <p className='text-center text-[#4c84ff] text-lg mb-4'>Validated</p>
            )}
            <h3 className="text-2xl font-semibold mb-2 text-center">{validationResult.data.companyName}</h3>
            <p className="text-gray-600 mb-2">MC Number: {validationResult.data.docketNumber}</p>
            <p className="text-gray-600 mb-2">DOT Number: {validationResult.data.dotNumber}</p>
            <p className="text-gray-600 mb-4">{validationResult.data.companyType}</p>
            <p className="text-center mb-4">{validationResult.data.companyAddress}</p>
            <hr className="w-full border-gray-300 mb-4" />
            <table className="w-full text-left mb-4">
              <tbody>
                <tr>
                  <td className="font-semibold">Contact Person:</td>
                  <td>{validationResult.data.companyContact}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Email:</td>
                  <td>{validationResult.data.companyEmail || 'Not Provided'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Phone:</td>
                  <td>{validationResult.data.companyPhone || 'Not Provided'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Cell:</td>
                  <td>{validationResult.data.companyCell || 'Not Provided'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Status:</td>
                  <td>{validationResult.data.status}</td>
                </tr>
              </tbody>
            </table>
            <hr className="w-full border-gray-300 mb-4" />
            <h4 className="text-xl font-semibold mb-2">Vetting Concerns</h4>
            <table className="w-full text-left mb-4">
              <tbody>
                <tr>
                  <td className="font-semibold">Operating Authority:</td>
                  <td>{validationResult.data.vettingConcerns.authority ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Address:</td>
                  <td>{validationResult.data.vettingConcerns.address ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Insurance:</td>
                  <td>{validationResult.data.vettingConcerns.insurance ? 'Yes - Recommend additional vetting of insurance coverage- Be certain that coverage is active for the VIN number . Unable to verify with 100 percent certainty the accuracy of the COI on file.' : 'No'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Phone:</td>
                  <td>{validationResult.data.vettingConcerns.phone ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Cell Phone:</td>
                  <td>{validationResult.data.vettingConcerns.cell ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Email:</td>
                  <td>{validationResult.data.vettingConcerns.email ? 'Yes' : 'No'}</td>
                </tr>
              </tbody>
            </table>
            <hr className="w-full border-gray-300 mb-4" />
            {/* <h4 className="text-xl font-semibold mb-2">Risk Info</h4>
            <p className="text-gray-600 mb-4">{validationResult.data.validationDetails[0].message}</p> */}
            {validationResult.data.validationDetails.length > 0 && (
              <>
            <h4 className="text-xl font-semibold mb-2">Caution Info</h4>
            <p className={`mb-4 font-bold ${validationResult.data.validationDetails[0].status === 'warning'? 'text-orange-400': 'text-gray-600'}`}>{validationResult.data.validationDetails[0].message}</p>
            </>
            )}
            <hr className="w-full border-gray-300 mb-4" />
            <h4 className="text-xl font-semibold mb-2">Authority Info</h4>
            <table className="w-full text-left mb-4">
              <tbody>
                <tr>
                  <td className="font-semibold">Broker Authority:</td>
                  <td>{validationResult.data.authority.authBroker}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Broker Revocation:</td>
                  <td>{validationResult.data.authority.authBrokerRevocation ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Common Authority:</td>
                  <td>{validationResult.data.authority.authCommon}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Common Revocation:</td>
                  <td>{validationResult.data.authority.authCommonRevocation ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Contract Authority:</td>
                  <td>{validationResult.data.authority.authContract}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Contract Revocation:</td>
                  <td>{validationResult.data.authority.authContractRevocation ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td className="font-semibold">Dot Status:</td>
                  <td>{validationResult.data.authority.dotStatus}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col gap-4">
            <svg className="h-20 w-20 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
            <p className="text-gray-700 text-[1.3rem] font-black">Oops! Something went wrong.</p>
            <p className="text-gray-700 text-center">{whyNull || 'No data found for the given MC Number and Purchase Code.'}</p>
          </div>
        )}
        {showScrollButton && (
          <button
            className="fixed bottom-10 bg-[#4c84ff] text-white px-4 py-2 rounded-lg z-550 left-0 right-0 mx-auto w-40"
            onClick={scrollToBottom}
          >
            Scroll to Bottom
          </button>
        )}
      </div>
    </div>
  );
};

export default ValidationResult;
